@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

section{
  width: 1280px;
  margin: auto;
}

section#about,section#portofolio{
  border: solid 7px #ffba3e;
}

.container-center{
  width: 1280px;
  margin: auto;
}

.logo-container{
  width: 85px;
  height: 96px;
  background-color: #ffba3e;
  margin-top: -1px;
  margin-left: 10%;
}

.header-page{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 1280px;
  margin:auto;
  z-index: 11111;
  position: relative;
}

.header-page .header-navbar{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-top: 65px;
  margin-right: 10%;
}

.header-page .header-navbar a{
text-decoration: none;
font-size: 17px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.02px;
color: #d4d4d4;
margin-bottom: 7px;
}

.header-page .header-navbar a:hover{
  color: #ffba3e;
  }


  .active-navbar{
    color: #ffba3e !important;
  }

/*****Home****/
.container-home{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 140px;
  position: relative;
}

.container-home .container-descrpiton{
  display: flex;
  flex-flow: column;
  width: 340px
}

.container-home .container-descrpiton h2{
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.04px;
  color: #3a4145;
  margin: 0;
  margin-bottom: 7px;
  text-align: start
}

.container-home .container-descrpiton h2:nth-child(2){
  margin-bottom: 40px;
}

.container-home .container-descrpiton h4{
  font-size: 15px;
  margin: 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #3a4145;
  text-align: start
}

.container-home .container-descrpiton h1{
  font-size: 40px;
  margin: 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  color: #3a4145;
  text-align: start
}

.container-home .container-descrpiton hr {
  border: none;
  border-top: 2px dotted #ffba3e;
  height: 35px;
  width: 100%;
  margin: 0px;
}

.container-home .container-descrpiton .container-bar {
  width: 85px;
  height: 270px;
  margin:auto;
  background-color: #ffba3e;
  margin-bottom: 0px;
  position: absolute;
  bottom: 0px;
  left: 10%;
}

.container-home .container-image img{
 max-width: 800px;
 object-fit: contain;
}


/*****About****/
.container-about{
  display: flex;
  flex-flow: row;
  margin-top: -60px;
  position: relative;
}

.container-about .container-center{
  display: flex;
  flex-flow: row; 
  margin-top: 140px
}


.container-about .container-about-left{
  background-color: #ffba3e;
  width: 213px;
  display: flex;
  flex-flow: column;
  position: absolute;
    height: 100%;
    align-items: center;
    top: 0px;
}


.container-about .container-center .container-about-row{
  display: flex;
  flex-flow: row
}


.container-about .container-column-about{
  display: flex;
  flex-flow: column;
  z-index:2;
  width: 213px;
  margin-left: 15%;
}

.container-about .container-column-about h4{
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #fff;
  margin-bottom: 20px;
  text-align: start;
}

.container-about .container-column-about:last-child h4{
  color:black;
}

.container-about .container-column-about:last-child h4 a{
  color: #ffba3e;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  margin-bottom: 20px;
}


.container-about .container-descrpiton{
  display: flex;
  flex-flow: column;
  margin-left: 150px;
  width: 500px;
}


.container-about .container-descrpiton h4{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #3a4145;
  font-weight: 500;
  text-align: justify;
}

.container-about .container-descrpiton label{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #3a4145;
  margin-bottom: 7px;
  text-align: justify;
}

/****Experience****/

.container-experience .container-center{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 140px;
}


.container-experience .title-container{
  position: relative;
  height: 70px;
}

.container-experience  .title-container h4{
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 6px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #3a4145;
}

.container-experience  .title-container label{
  position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.81px;
    color: rgba(255, 186, 62, 0.3);
}


.timeline-block:nth-child(1):before {
  content: '';
  position: absolute;
  top: 11px;
  left: 5px;
  margin-left: 0px;
  width: 1px;
  height: 100%;
  background: #ffba3e;
  z-index: 1;
}

.timeline-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  position: relative
}


.timeline-block .marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #ffba3e;
  background: #ffba3e;
  margin-top: 10px;
  z-index: 9999
}

.timeline-content{
  display: flex;
  flex-flow: column;
  margin-left: 20px;
  margin-bottom: 20px;
}

.timeline-content .timline-content-header{
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
  margin-top: -15px;
}

.timeline-content .timline-content-header h3{
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.51px;
  color: #ffba3e;
  margin-bottom: 2px;
}

.timeline-content .timline-content-header span{
  font-size: 13px;
  letter-spacing: 0.35px;
  color: #3a4145;
}

.timline-content-description-list{
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
}

.timline-content-description-list .timline-content-description-item{
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
}

.timline-content-description-list .timline-content-description-item h5{
  font-size: 15px;
  letter-spacing: 0.4px;
  color: #3a4145;
  font-weight: bold;
  margin-bottom: 5px;
}

.timline-content-description-list .timline-content-description-item span{
  font-size: 15px;
  letter-spacing: 0.4px;
  color: #3a4145;
}

.timeline-content .date{
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #3a4145;
}

.container-right {
  margin-top: 70px;
}

.container-right .container-timeline{
  background-color: #ffba3e;
  padding: 20px;
}

.container-right .timeline-content .timline-content-header{
  margin-top:5px
}

.container-right .timeline-content .timline-content-header h3{
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.51px;
  color: #fff;
  margin-top: 2px;
}

.container-right .timline-content-description-list .timline-content-description-item h5 {
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  color: #3a4145;
  margin-top: 0px
  
}


.container-right .timeline-block .marker {
  border: 2px solid #fff;
  background: #fff;
}

.container-right .timeline-block:before {
  background: #fff;
}

/***Circle skills */
.container-sckills{
  margin-top: 60px;
  position: relative;
}

.container-circle{
  position: absolute;
  top: 140px;
}

.img-cirlce{
  width: 50px;
  height: 50px;
  object-fit: contain;
}


/**Portofolio */
section#portofolio{
  z-index: 1;
  position: relative;
  background-color: white;
  margin-top: 100px;
  height: 100%;
}

.container-portofolio{
  margin-top: 140px;
  margin-bottom: 140px;
}

.container-portofolio .slide .container-slide {
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
}


.slide {
  transform: scale(0.6);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(0.9);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

 .slide .container-description-slider{
display: none;
}

 .slide.activeSlide .container-description-slider{
  display: flex;
  flex-flow: column;
  margin-top: 20px;
}

.container-slide .container-description-slider h5{
  font-family: Futura;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #3a4145;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.container-slide .container-description-slider label{
  font-family: FuturaBT;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #3a4145;
  margin-bottom: 15px;
}


.container-slide .container-description-slider .container-tech-logos{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}


.container-slide .container-description-slider .container-tech-logos .logo-tech{
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 10px;;
}


.container-slide .container-description-slider .container-tech-logos >div {
 display: flex;
}

.container-slide .container-description-slider .container-tech-logos a{
 text-decoration: none;
 cursor: pointer;
 background-color: #ffba3e;
 padding: 8px 18px;
 font-family: Futura;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #fff;
  border-radius: 8px;
}
