/***Circle skills */
#parent-circle {
  position: relative;
  width: 370px;
  height: 370px;
  border: 1px solid #ffba3e;
  border-radius: 50%;
  transform: rotate(0deg);
  transition: transform 0.7s linear;
  animation: rotate 7s infinite linear;

  .circle {
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    margin: -8%;
    border-radius: 50%;
    top: 50%; 
    left: 50%;
    background-color:#ffff;
    box-shadow: 0 4px 18px 2px rgba(0, 0, 0, 0.11);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &.item-1 {
      transform: translate(190px); // parent size/2
    }
    &.item-2 {
      transform: rotate(72deg) translate(-190px) rotate(-72deg);
    }
    &.item-3 {
      transform: rotate(72deg * 2) translate(190px) rotate(-72deg * 2);
    }
    &.item-4 {
      transform: rotate(72deg * 3) translate(-190px) rotate(-72deg * 3);
    }
    &.item-5 {
      transform: rotate(72deg * 4) translate(190px) rotate(-72deg * 4);
    }
    &.item-6 {
      transform: rotate(72deg * 5) translate(-190px) rotate(-72deg * 5);
    }
    &.item-7 {
      transform: rotate(72deg * 6) translate(190px) rotate(-72deg * 6);
    }

    &.item-8 {
      transform: rotate(72deg * 7) translate(-190px) rotate(-72deg * 7);
    }

    &.item-9 {
      transform: rotate(72deg * 8) translate(190px) rotate(-72deg * 8) ;
    }

    &.item-10 {
      transform: rotate(72deg * 9) translate(-190px) rotate(-72deg * 9);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  } 
}



